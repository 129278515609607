
import { defineComponent } from "vue";
import BaseImgCircle from "../BaseImgCircle.vue";
import DotsVerticalIcon from "icons/DotsVertical.vue";

const __default__ = defineComponent({
  props: {
    commentId: Number,
    thumbnail: String,
    nickname: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    tstamp: {
      type: String,
      default: "",
    },
    isAuthor: {
      type: Boolean,
      default: false,
    },
    isPostAuthor: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: Number,
    },
    inside: {
      type: Boolean,
      default: false,
    },
    paddingLeft: {
      type: String,
      default: "50px",
      validator(value: string) {
        return value.includes("px");
      },
    },
    isActiveReply: {
      type: Boolean,
      default: true,
    },
  },
  components: { BaseImgCircle, DotsVerticalIcon },
  emits: ["modifyComment", "selectReplyTarget", "goPost"],
  computed: {
    transAgo() {
      return this.$timeAgo(this.$convertToDate(this.tstamp));
    },
  },
  methods: {
    extractNickname() {
      if (this.content) {
        let content = this.content;
        let mention = content.substring(0, content.indexOf(" "));
        if (mention.includes("@")) {
          return mention;
        }
      }
      return "";
    },
    extractContent() {
      if (this.extractNickname()) {
        let content = this.content;
        content = content.substring(content.indexOf(" "), content.length);
        return content;
      }
      return this.content;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0aff1c2d": (_ctx.paddingLeft)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__