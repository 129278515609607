
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    inside: {
      type: Boolean,
      default: false,
    },
    paddingLeft: {
      type: String,
      default: "50px",
      validator(value: string) {
        return value.includes("px");
      },
    },
  },
  computed: {
    activatedPaddingSize(): string {
      if (this.inside) {
        return this.paddingLeft;
      }
      return "0px";
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "232d6dfa": (_ctx.activatedPaddingSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__